import React, { useEffect } from 'react'
import Banner from '../components/Banner'
import Navbar from '../components/Navbar'
import Policy from '../components/policy'
import '../components/BannerContent.css'
import Footer from '../components/Footer'

function Privacy() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    return (
        <>
            <Navbar />
            <Banner {...{title: "PERSONVERNBETINGELSER", underTxt: "Sist oppdatert: 29.03.2021"}} />
            <div align="center"><p className="banner-content" align="center">
                <br/><br/>
                <Policy />
                </p></div>
            <Footer />
        </>
    )
}

export default Privacy
