import React from 'react'

function Vedlikehold() {
    return (
        <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
            <h1>Grasrota.com er under vedlikehold</h1>
        </div>
    )
}

export default Vedlikehold
