import React from 'react'
import "./Auth.css"
import Login from "./Login"

function AuthPage() {
    return (
        <div className="auth-parent">
            <Login />
        </div>
    )
}

export default AuthPage
