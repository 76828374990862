import React, { useEffect } from 'react'
import Banner from '../components/Banner'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../components/Neworg.css'

function Neworg() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <Navbar />
            <Banner {...{title: "HVORDAN REGISTRERE?", underTxt: ""}} />
            <div align="center">
                <div className="neworg-container">
                    <br/>
                    <br/>
                    <h2>Steg 1</h2>
                    <img src="/assets/Steg1.png" alt="steg1"/>
                    <div><p>Last ned Grasrota appen fra App store eller google play. Etter nedlastningen trykker du på «lag en konto» (markert med rød pil). Du er nå på registeringssiden, hvor du fyller inn all nødvendig info for registering av en ny bruker. Dette vil også bli den administrerende brukeren i organisasjonen du senere legger til. Når du kommer til steg 3 av registeringen, fortsetter du til steg 2 av dokumentasjonen.</p></div>
                    <br/><br/><br/>
                    <h2>Steg 2</h2>
                    <div><p>Søk opp navnet på din organisasjon og velg den ved å trykke på den. Hvis din organisasjon ikke er registrert, velger du «har vi glemt noen?» i bunnen av siden. Herfra velger du «fyll inn org. info» (også markert med rød pil), og går videre til steg 3 av dokumentasjonen.</p></div>
                    <img src="/assets/Steg2.png" alt="steg2"/>
                    <br/><br/><br/>
                    <h2>Steg 3</h2>
                    <img src="/assets/Steg3.png" alt="steg3"/>
                    <div><p>Du er nå på en side lik bildet. Fyll ut all nødvendig info, før du går videre (Husk at du har ansvar for at informasjon som blir gitt er korrekt). Vent gjerne 10 sekunder før du søker på nytt. Etter at du har søkt og valgt organisasjonen du la til, kan du fullføre registreringen av brukeren din ved å trykke på «fullfør».</p></div>
                    <br/>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Neworg
