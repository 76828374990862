import SubmitBtn from '../../../shared/constant/SubmitBtn'
import React, { useEffect, useState } from 'react'
import { collection, getDocs,limit, query, where} from '@firebase/firestore'
import { fireStore } from '../../../firebase'

function Orgs() {
  const [orgs, setorgs] = useState([])

  async function fetchOrgs() {
    const dataCol = collection(fireStore, 'org/');
    const q = query(dataCol, limit(50), where("numberOfUsers", "!=", 0));

    const dataSnapshot = await getDocs(q);
    const dataList = dataSnapshot.docs.map((doc,i) => {
      const docData = doc.data()
      docData["id"] = doc.id
      docData["score"] = docData["score"] * 100

      getUsersInOrg(doc.id).then((price) => {
        document.getElementById("price-"+i).innerText = price+" kr"
      }).catch((err) => {
        console.log(err);
      });

      return docData
    });
    setorgs(dataList)
  }

  async function getUsersInOrg(id) {
    const usersInOrg = collection(fireStore, 'users/');
    const quer = query(usersInOrg, where("org", "==", id));

    var price = 0;

    const users = await getDocs(quer);
    users.docs.map((doc) => {
      price += "price" in doc.data() ? doc.data()["price"] : 0
    });
    return price/100;
  }

  useEffect(() => {
    fetchOrgs()
  }, [])

  return (
    <div>
    <h2>Organisasjoner</h2>

    <br />
    <div className='table-container'>
      <table className='quiz-table'>
        <tr>
          <th></th>
          <th>Navn</th>
          <th>Prosent til bonus	</th>
          <th>Samlet</th>
        </tr>
        {orgs.map((item,i) => {
          console.log(item["price"]);
          return <tr><td>{item["image"]}</td><td>{item["navn"]}</td><td>{item["score"]} %</td><td id={'price-'+i}>kalkulerer...</td></tr>
        })}
      </table>
    </div>
  </div>
  )
}

export default Orgs

/*


import SubmitBtn from '../../../shared/constant/SubmitBtn'
import React, { useEffect, useState } from 'react'
import { collection, getDocs,limit, query, where} from '@firebase/firestore'
import { fireStore } from '../../../firebase'

function Orgs() {
  const [orgs, setorgs] = useState([])

  async function fetchOrgs() {
    const dataCol = collection(fireStore, 'org/');
    const q = query(dataCol, limit(25));

    const dataSnapshot = await getDocs(q);
    const dataList = dataSnapshot.docs.map(async(doc) => {
      const docData = doc.data()
      docData["id"] = doc.id


      const usersInOrg = collection(fireStore, 'user/');
      const query = query(usersInOrg, where("org", "==", doc.id));
      const users = await getDocs(query);
      users.docs.map((doc) => {
        price += doc.data()["price"]
      });

      docData["price"] = price
      docData["score"] = docData["score"] * 100


      return docData
    });

    setorgs(dataList)
  }
*/