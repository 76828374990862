import React, { useEffect, useState } from 'react';
import AuthPage from '../components/dashboard/auth/Auth'
import { auth } from '../firebase';
import LoaderBar from '../shared/constant/LoaderBar';
import Dashboard from './Dashboard';

function Auth() {
const [user, setuser] = useState("")
const [isLoading, setisLoading] = useState(true)

  useEffect(() => {
    auth.getAuth().onAuthStateChanged(async function (user) {
      //if (user && user.uid === process.env.ADMIN_USER_UID) {
        if (user) {
         setuser(user)
      } else {
        setuser()
      }
      setisLoading(false)
    });
  }, [])

  return (<div style={{background: "rgb(247, 247, 247)",height: "100vh",width: "100vw"}}>
      {!isLoading ? user ? <Dashboard user={user}/> : <AuthPage /> : <center><br /><br /><LoaderBar /></center>}
      </div>
  );
}

export default Auth
