import React, { useEffect, useState } from 'react'
import NewQuiz from './NewQuiz'
import "./Quizes.css"
import QuizList from './QuizList'

function Quizes() {
  const [showList, setshowList] = useState(true)
  const [editId, seteditId] = useState(null)

  const changeView = (id=null) => {
    seteditId(id)
    setshowList(!showList)
  }

  return (
    <div>
      {showList ? <QuizList changeview={(e)=>changeView(e)} /> :<NewQuiz editId={editId} changeview={(e)=>changeView(e)} />}
    </div>
  )
}

export default Quizes