import React from 'react'
import './Frontpage.css'

function Frontpage() {
    return (
        <div className="frontpage-container">
            <div className="right-container">
                <img id="backImg" src="/assets/bigBack.png" alt="bigBack-background"/>
                <img id="phoneImg" src="/assets/phone.png" alt="phone"/>
            </div>
            <div className="left-container">
                <div className="team-text">
                    <p>Grasrota, er appen som gjør det enkelt og gøy å delta i ukentlig quiz konkurranse, for folk flest. Ta quizen med både venner, familie og kollegaer i kampen om førsteplassen på tabellen!</p>
                    <a href='https://grasrota2.page.link/app'><img src="/assets/android.png" alt="android"/></a>
                    <a href='https://grasrota2.page.link/app'><img src="/assets/ios.png" alt="ios"/></a>
                    {/*<img src="/assets/vipps.png" alt="vipps"/>*/}
                    <p className="small-info-txt">*Appen er gratis, bare å laste ned.</p>
                </div>
            </div>
        </div>
    )
}

export default Frontpage