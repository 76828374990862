import React from 'react'
import { Link } from 'react-router-dom'

function termsText() {
    return (
        <div>
            <br/>
<h3>VILKÅR FOR GRASROTA APPEN (QUIZ-DELTAGERE) DEL 1.</h3>
 <br />
 <strong>1. GENERELLE</strong><br />
 1.1 Disse vilkår og betingelser ("Vilkårene") gjelder for din bestilling av quiz abonnement i Grasrota Appen hos Kompetanseløftet AS.
 <br />
 1.2 Kompetanseløftet AS forbeholder seg retten til å kunne endre vilkårene. En slik endring vil ikke ha noen innvirkning på ordrer som ble gjort før de endrede vilkårene blir publisert i Appen eller på websiden.
 <br />
 1.3 For eventuelle spørsmål eller kommentarer vedrørende vilkårene, vennligst kontakt oss på e-post: <a href = "mailto: post@grasrota.com">post@grasrota.com</a> . Les også alle vilkårene for bruk av Grasrota i del 2, utover de generelle vilkårene.
  <br /><br />
 <strong>2. KJØP AV QUIZ ABONNEMENT-PRODUKT</strong><br />
 2.1 Kjøpet ditt registreres når du har fylt inn personalia og Vipps-informasjon i betalingsvinduet på App siden, og trykket “betal”. 
 <br />
 2.2 Abonnementet-produkt du har valgt trekkes en gang per måned fra vippskontoen du har oppgitt. Abonnementet løper helt til du velger å si opp abonnementet.
 <br /><br />
 <strong>3. PRISER OG TRANSAKSJONSKOSTNADER</strong><br />
 3.1 Alle priser er oppgitt i NOK. De oppgitte prisene for Quiz abonnement-produkt er den totale prisen du som kjøper skal betale pr måned. Denne prisen inkluderer alle avgifter og tilleggskostnader. 
 <br /><br />
 <strong>4. BETALING</strong><br />
 4.1 Betalinger gjennomføres via betalingssystemet til Vipps.
 <br /><br />
 <strong>6. ANGRERETT OG OPPSIGELSE AV ABONNEMENT</strong><br />
 6.1 Dersom du har kjøpt abonnement, kan du når som helst si opp abonnementet. 
 <br />
 Du vil fortsette å kunne bruke tjenesten som abonnent ut den gjeldende perioden (1 mnd. fra siste auto trekk), men du blir ikke trukket på nytt påfølgende måned.
 <br />
 6.2 Dersom det har skjedd en feil og du ønsker å trekke bestillingen, må tilbaketrekning skje i skriftlig form via e-post til <a href = "mailto: post@grasrota.com">post@grasrota.com</a> .
 <br />
 <br />
 <br />
 <br />
 <br />
<h3>ALLE VILKÅRENE FOR BRUK AV GRASROTA DEL 2</h3>
<br/>
<h3>1. Plikter og rettigheter ved bruk av Grasrota – aksept av vilkår</h3>
All bruk av Grasrota reguleres av disse vilkårene, heretter omtalt som Vilkårene. <br/>
Dersom du tar i bruk Grasrota, anses dette som en aksept av Vilkårene. <br/>
Du er ansvarlig for at passordet du benytter i Tjenesten er unikt, at det ikke er benyttet andre steder og at passordet er av en slik karakter at det er vanskelig for andre å gjette seg frem til. <br/>
<br/>
<br/>
<h3>2. Administratorer</h3>
En administrator er den som oppretter en profil i Grasrota eller som på annen måte har fått tillatelse til å være administrator. Aldersgrensen for å være administrator er 16 år. <br/>
Som administrator har du et særlig ansvar for: <br/>
• Å innhente samtykke fra de du legger til. Hvis de du legger til er under 16 år må samtykke innhentes fra de foresatte. <br/>
• Å ikke oppfordre til eller legge til rette for at medlemmer deler sensitive opplysninger om seg selv eller andre. <br/>
<br/>
<br/>
<h3>3. Aldersgrense</h3>
Selv om aldersgrensen for å være gruppeadministrator er 16 år, kan Grasrota benyttes av barn under 16 år som er registrert som brukere av barnets foresatte. <br/>
<br/>
<br/>
<h3>4. Endringer i Vilkårene</h3>
Grasrota kan endre Vilkårene. Ved vesentlige endringer vil vi varsle deg på hensiktsmessig måte. Ved andre endringer vil nye vilkår bli publisert på grasrota.com. Hvis du da fortsetter å bruke Grasrota anses du for å ha akseptert de nye vilkårene. <br/>
<br/>
<br/>
<h3>5. Din bruk av Grasrota</h3>
Ved å ta i bruk Grasrota aksepterer du at du ikke skal: <br/>
• Bruke Grasrota på annen måte enn i samsvar med formålet og intensjonene med Grasrota. <br/>
• Handle i strid med disse Vilkårene. <br/>
• Publisere innhold eller annet materiale som er, eller kan oppfattes som, upassende, uanstendig, støtende eller nedverdigende. <br/>
• Publisere innhold eller annet materiale som noen andre har rettigheter til, uten avtale med rettighetshaver(ne). <br/>
• Kopiere, publisere, videre lisensiere, endre, forstyrre, skaffe deg urettmessig tilgang, til (eller forsøke noe av dette) til Grasrota, dens programvare, brukergrensesnitt eller annet innhold i Grasrota. <br/>
• Skaffe deg tilgang til, kopiere, publisere eller på annen måte benytte personopplysninger om andre Grasrota-brukere for noe annet formål enn å opprette, administrere og delta i Grasrota. <br/>
• Legge til deltagere i Grasrota som du vet ikke ønsker å være deltager eller som det er grunn til å regne med at ikke ønsker å være deltager i Grasrota. <br/>
• Distribuere noe av innholdet i Grasrota. Med innhold forstås blant annet tekst, bilde, lyd, video, programvare, script eller annet materiale som er tilgjengelig i Grasrota. <br/>
• Forsøke å omgå sikkerhetsrelaterte funksjoner i Grasrota. <br/>
Hvis du bruker Grasrota i strid med disse prinsippene eller Vilkårene for øvrig kan vi stenge din brukerkonto og avslutte avtalen med umiddelbar virkning. Grasrota kan på eget initiativ monitorer og undersøke konkret eller tilfeldig utvalgt innhold med mål om å avdekke brudd på gjeldende Vilkår. Vi kan også fjerne eller sensurere innhold eller annet materiale som vi mener strider mot disse Vilkårene, formålet, eller intensjonen med Grasrota. <br/>
<br/>
<br/>
<h3>6. Avbrudd</h3>
Grasrota kan fra tid til annen være helt eller delvis utilgjengelig på grunn av vedlikehold, oppdateringer eller av andre grunner. Vi vil i den grad det er praktisk mulig, og på slik måte vi mener er hensiktsmessig, gi informasjon om begrensninger i tilgjengeligheten. <br/>
<br/>
<br/>
<h3>7. Innhold og opphavsrett</h3>
Ved bruk av Grasrota kan du få tilgang til variert innhold og informasjon, blant annet: <br/>
• Kontaktinformasjon; herunder, navn, telefon, epost, adresser, bilder <br/>
• Aktiviteter; herunder tid, sted, hvem som skal delta <br/>
• Spørsmål; herunder hvem som har svart og hva de har svart <br/>
• Oppgaver; herunder hvem som skal gjøre noe <br/>
• Kommentarer knyttet til ovennevnte <br/>
Kompetanseløftet AS er eier av Grasrota, og har samtlige opphavsrettslige, varemerkerettslige og andre immaterielle rettigheter til tjenesten og innhold skapt av eller for Kompetanseløftet AS. Du beholder alle eierrettigheter til ditt innhold. Det betyr samtidig at innhold eller annet materiale som er publisert av andre brukere er deres eiendom. Du kan ikke bruke slikt innhold med mindre du har en avtale med eieren av innholdet. <br/>
Som bruker er du selv ansvarlig for det innhold som du publiserer gjennom Grasrota. <br/>
Innhold som strider mot en tredjeparts rettigheter vil bli fjernet fra Grasrota raskest mulig etter at vi får kjennskap til slikt innhold. <br/>
<br/>
<br/>
<h3>8. Opphør av avtaleforholdet</h3>
Denne avtalen gjelder inntil den sies opp av en av partene. Du kan avslutte avtalen ved å slutte å bruke Grasrota, og slette din brukerprofil. Hvis vi ønsker å avslutte avtalen vil vi i den grad det lar seg gjøre varsle deg om dette på forhånd. <br/>
<br/>
<br/>
<h3>9. Personopplysninger</h3>
Behandling av personopplysninger i forbindelse med Grasrota er regulert i vår Privacy Policy <Link to="/policy">(klikk her)</Link>. <br/>
{/*Kontonummer. For å ta i bruk Grasrota må du oppgi kontonummeret ditt i Grasrota-profilen din, og velge mottaker(e) av bidrag. Grasrota kan ikke verifisere hvorvidt kontonummeret du registrerer faktisk er ditt eget. Du kan ikke registrere et kontonummer tilhørende en annen enn deg selv uten først å ha innhentet kontoeierens samtykke til slik registrering. I forbindelse med at du innhenter slikt samtykke må du også informere kontoeieren om disse Vilkårene. Kontonumre lagres hos Kompetanseløftet AS leverandør av betalingstjenester. <br/>
Utbetalinger. Kompetanseløftet AS foretar månedlige utbetalinger, fratrukket vår prosentandel, til det eller de formål som du har utpekt. Utbetaling skjer likevel først etter at Kompetanseløftet AS har mottatt fullstendig oppgjør fra Vipps, normalt innen 30 dager, regnet fra første dag i påfølgende måned etter at kjøp er gjennomført. Eventuelle skatter og avgifter som følge av utbetalinger er ikke Kompetanseløftet AS sitt ansvar. Det er administrator for det enkelte lag eller gruppe som legger inn kontonummeret til mottaker av utbetalingene. <br/>
Utbetaling ved sletting av grupper. Hvis en administrator sletter gruppen og gruppen har innsamlede midler som ikke er utbetalt, må administrator innen 30 dager etter at gruppen er slettet ta kontakt med oss for å få det utbetalt. <br/>
Analyse. Kompetanseløftet AS kan bruke kjøpshistorikk for å analysere trender eller tjenester. Ved gjennomføring av slike analyser vil det ikke bli benyttet personidentifiserbare opplysninger. <br/>*/}
<br/>
<br/>
<h3>10. Tvisteløsning</h3>
Enhver tvist som måtte oppstå mellom deg og Kompetanseløftet AS reguleres av norsk rett. Eventuelle tvister som ikke løses i minnelighet avgjøres med Follo tingrett som verneting. <br/>
<br/>
<br/>
<strong>Kontaktopplysninger</strong> <br/>
Eier av Grasrota: Kompetanseløftet AS, Org nr. 918 061 711 <br/>
Grenåveien 43, 1447 Drøbak. <br/>
E-post: <a href = "mailto: post@grasrota.com">post@grasrota.com</a> <br/>

        </div>
    )
}

export default termsText
