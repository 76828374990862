import {
    Route,
    BrowserRouter as Router,
    Routes
} from 'react-router-dom';
import './App.css';
import About from './pages/About';
import Auth from './pages/Auth';
import Home from './pages/Home';
import Lisens from './pages/Lisens';
import LisSuc from './pages/LisSuc';
import Neworg from './pages/Neworg';
import Oftespor from './pages/Oftespor';
import Privacy from './pages/Privacy';
import Salgbeting from './pages/Salgbeting';
import Terms from './pages/Terms';
import Vedlikehold from './pages/Vedlikehold';

function App() {
    return (
        <Router>
            <Routes>
                <Route path='/'
                    exact element={<Home />}
                /> <Route path='/panel'
                    element={<Auth />}
                /> <Route path='/about'
                    exact element={<About />}
                /> <Route path='/terms'
                    exact element={<Terms />}
                /> <Route path='/policy'
                    exact element={<Privacy />}
                /> <Route path='/neworg'
                    exact element={<Neworg />}
                /> <Route path='/quest'
                    exact element={<Oftespor />}
                /> <Route path='/vedlikehold'
                    exact element={< Vedlikehold />}
                /> <Route path='/salgsbetingelse'
                    exact element={<Salgbeting />}
                /> <Route path='/lisens'
                    exact element={<Lisens />}
                /> <Route path='/lisens/success'
                    exact element={<LisSuc />}
                />
            </Routes>
        </Router>
    );
}

export default App;