import { getFunctions, httpsCallable } from 'firebase/functions'
import React, { useEffect, useState } from 'react'
import Banner from '../components/Banner'
import Footer from '../components/Footer'
import "../components/Lisens.css"
import LisensData from '../components/LisensData'
import Navbar from '../components/Navbar'
import fire from '../firebase'
import SubmitBtn from '../shared/constant/SubmitBtn'

function Lisens() {
    const [klubb, setklubb] = useState("")
    const [orgnr, setorgnr] = useState("")
    const [kontaktperson, setkontaktperson] = useState("")
    const [mobil, setmobil] = useState("")
    const [epost, setepost] = useState("")
    const [kontonr, setkontonr] = useState("")
    const [tok, settok] = useState("")

    const [signertDato, setsignertDato] = useState("")
    const [signert, setsignert] = useState("")
    const [forstatt, setforstatt] = useState(false)

    const [isLoading, setisLoading] = useState(false)

    const [includeForm, setincludeForm] = useState(false)
    console.log(klubb, '<<<<<<<<<<<<<<<<<<<<<<<------1');
    useEffect(() => {
        const params = new URL(window.location.href).searchParams;
        const token = params.get('t')
        settok(token)
        if (token !== null) setincludeForm(true)
        window.scrollTo(0, 0);

        const functions = getFunctions(fire)
        const getInfo = httpsCallable(functions, "getLisensInfo")
        console.log(token, '<<<<<<<<<<<<<<<<<<<<<<<-----2');
        getInfo({
            token: token
        }).then((result) => {
            console.log(result, '<<<<<<<<<<<<<<<<<<<<<<<-----3');
            try {
                setepost(result["data"]["email"])
                setklubb(result["data"]["klubb"])
                setkontonr(result["data"]["konto"])
                setkontaktperson(result["data"]["name"])
                setmobil(result["data"]["tlf"])
                setorgnr(result["data"]["org"])
            } catch (error) { }
        })
    }, [])

    

    const upload = () => {
        setisLoading(true)
        const functions = getFunctions(fire)
        const upd = httpsCallable(functions, "sendContract")
        upd({
            signing: signert,
            token: tok
        }).then((val) => {
            console.log(val.data);
            if (val.data["status"]) {
                window.location.href = window.location.origin + "/lisens/success"
            } else {
                alert("Noe gikk galt")
            }
        }).catch((e) => {
            setisLoading(false)
        })
    }

    return (
        <>
            {!includeForm ? <>
                <Navbar />
                <Banner {...{ title: "AVTALE MELLOM PARTERE" }} />
            </> : ""}
            <div align="center"><p className="banner-content" align="center">
                <div><LisensData /></div>
                {includeForm ? <div className='form-parent'>
                    <strong>Klubb / Forening / Organisasjon / Lag</strong><br />
                    <input disabled type="text" value={klubb} placeholder="(Fyll inn rett navn her)" />
                    <br /><br />

                    <strong>Organisasjonsnummer</strong><br />
                    <input disabled type="text" value={orgnr} placeholder="(Fyll inn rett org.nr om dere har det)" />
                    <br /><br />

                    <strong>Navn / kontakperson</strong><br />
                    <input disabled type="text" value={kontaktperson} placeholder="(Fyll inn navnet,fornavn og etternavn her)" />
                    <br /><br />

                    <strong>Mobilnummer</strong><br />
                    <input disabled type="text" value={mobil} placeholder="(Fyll inn telefonnummer til kontaktperson her)" />
                    <br /><br />

                    <strong>Epost</strong><br />
                    <input disabled type="text" value={epost} placeholder="(Fyll inn e-post adressen til den som kan kontaktes)" />
                    <br /><br />

                    <strong>Kontonummer</strong><br />
                    <input disabled type="text" value={kontonr} placeholder="(Fyll inn kontonummer til deres klubb / forening / organisasjon / Lag her)" />
                    <br /><br />

                    <strong>Signatur</strong><br />
                    <p>Denne avtalen er en enkel og god regulering av vårt samarbeid. Hele samarbeidet skal være basert på hygge og glede. Her skal ingen føle annet en trygghet, velvære og glede. Vi ser frem til mange gode år med felles innsats på grasrota.</p>
                    <input type="text" onChange={(e) => setsignert(e.target.value)} placeholder="(Signering skjer ved at man skriver inn sitt navn her maskinelt)" />
                    <br /><br />

                    <div style={{ textAlign: "center" }}><SubmitBtn isLoading={isLoading} onclick={() => upload()} text={"Send inn avtalen"} /></div>
                    <br />

                </div>
                    : ""}            </p>
            </div>
            {!includeForm ? <Footer /> : ""}
        </>
    )
}

export default Lisens