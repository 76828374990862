import React from 'react'
import { Link } from 'react-router-dom'
import "./navbar.css"

function Navbar({email}) {
  return (
    <div className='navbar-parent-dashboard'>
        <div className="profile-info">
            <h3>Admin</h3>
            <h5>{email}</h5>
        </div>
        <ul className='center'>
            <li><Link to={"/panel"} className='remove-link-style'>Quizes</Link></li>
            <li><Link  to={"panel/orgs"} className='remove-link-style'>Organisasjoner</Link></li>
        </ul>
    </div>
  )
}

export default Navbar