import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import { auth } from '../../../firebase';
import SubmitBtn from '../../../shared/constant/SubmitBtn';

function Login() {
    const [email, setemail] = useState("")
    const [password, setpassword] = useState("")
    const [isLoading, setisLoading] = useState(false)
    const [errorMsg, seterrorMsg] = useState("")

    const login = () => {
        if (email !== "" && password !== "") {
            setisLoading(true);
            auth.signInWithEmailAndPassword(auth.getAuth(),email,password).then((user) => {
                user.user.getIdToken().then((token) => {
                    console.log(token);
                })
            }).catch((err) => {
                console.log(err.message);
            setisLoading(false);
            seterrorMsg("Noe gikk galt!")
            });
        } else {
            setisLoading(false);
            seterrorMsg("Fyll alle feltene")
        }
    }

    return (
        <div>
            <h2>Logg inn</h2>
            <input placeholder="Email" className="data-input" type="email" onChange={(e) => setemail(e.target.value)} />
            <input placeholder="Passord" className="data-input" type="password" onChange={(e) => setpassword(e.target.value)} />
            <p className='errorMsg-red'>{errorMsg}</p>
            <SubmitBtn onclick={() =>login()} isLoading={isLoading} text={"Logg inn"} />
        </div>
    )
}

export default Login
