import React, { useEffect } from 'react'
import Banner from '../components/Banner'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

function Oftespor() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Navbar />
            <Banner {...{ title: "OFTE STILTE SPØRSMÅL", underTxt: "" }} />
            <div align="center"><p className="banner-content" align="center">

                <br/>
                <h3>Hvem er Grasrota til for?</h3>
                Grasrota er til for alle personer som driver en form for organisert aktivitet, eller som bare ønsker å delta i vår quizferdighet. Det kan være fotballspillere, kordeltakere, speidere, svømmetreneren/svømmerne eller bare en deltaker på en russbuss. Grasrota er ment for alle, et fellesskapet, og bedre det å være sammen. Trygt, gøy og enkelt!
                <br/>
                <br/>
                <h3>Hva er hovedfordelene med å bruke Grasrota?</h3>
                Fordelene med å bruke Grasrota er enkel, man tar i bruk en ferdige tilrettelaget, miljøvennlig quiz løsning, digitalt, tilgjengelig for alle. Her skal vi ikke drive med logistikk på loddbøker, doruller, kakelotteri, dugnad gjengen, banke dører, ringe rundt, Covid-19, penger borte på veien, kjøpe alle loddene selv, sende barna rundt i kveldsmørket for å nevne noe. 
                Her skal man kun delta miljøvennlig og det digitalt i en quiz konkurranse og ha det gøy. <br />
                I en egen avtale kan man få muligheten til å promotere/markedsføre grasrota appen og tjene penger for den jobben. Alle som ønsker å promotere/markedsføre grasrota appen gjør det gjennom en avtale som en administrator i en egen formidleravtale og lønnes etter denne. Kompetanseløftet AS er selger av quiz konkurranser gjennom sin App og sluttbrukeren/kjøperen forholder seg kun til Kompetanseløftet AS. 
                <br /><br />
                <h3>Koster det penger?</h3>
                Grasrota er helt gratis å laste ned! Den eneste kostnaden kommer når man velger å delta i quizen. Det er ingen skjulte kostnader. 
                <br/><br/>
                <h3>Hva er forretningsmodellen til Grasrota?</h3>
                Vårt hovedmål er å forenkle tilgangen til det å tjene penger for fotballspillere, kordeltakere, speidere, svømmetreneren/svømmerne, deltaker på en russbuss eller beboerne hver for seg i et borettslaget i Norge. <br />
                Grasrota tar utgangspunkt i et produkt som vi selger i form av en App, der brukerne abonnerer for å få tilgang på quizen umiddelbart. Med andre ord, «selger» vi en quiz, som brukerne kan benytte seg av. <br />
                Kompetanseløftet AS vil med denne modellen la personer også få en muligheten til å promotere/markedsføre grasrota appen-quiz og tjene litt penger for den jobben. Vi som alle andre vil bidra der vi kan på grasrota og det miljøvennlig, digitalt.
                <br />
                Jobben/arbeidet for å få promotere/markedsføre Appen blir å anse som en egen konsulenttjeneste/avtale og lønnes der etter.
                <br/>
                <br/>
                <h3>Jeg trenger mer hjelp - hvor kan jeg finne mer info?</h3>
                kontakte <a href = "mailto: post@grasrota.com">post@grasrota.com</a>. <br/> <br/>

                <h3>Jeg ønsker et samarbeid med Grasrota Appen?</h3>
                Send en epost til <a href = "mailto: post@grasrota.com">post@grasrota.com</a> også vil teamet vårt ta kontakt. <br/> <br/>

                <h3>Trenger jeg å bruke appen for å bruke Grasrota?</h3>
                Ja. Når en invitasjon blir sendt ut oppfordrer vi mottakere til å laste ned Grasrota appen, men man oppfordres også å fortsette og gi varsler på e-post om du skal informere/purre på dine kontakter. De første invitasjonen sendes ut per e-post, av deg/dere. Brukerne/mottakerne registrerer seg under ditt/deres idrettslag, klubb, forening eller organisasjon i sin egen nedlastede Grasrota app.
                <br/> <br/>
                <h3>Hvordan kommer jeg i gang som administrator med å opprette en profil for mitt idrettslag, klubb, forening, gruppe eller organisasjon?</h3>
                Du oppretter en bruker. På steg 3 hvor du skal velge en organisasjon kan du søke den ønskede organisasjonen. Hvis den ikke er registrert, kan du enkelt registrere den ved å trykke på «finner ikke din org?» i bunnen og fyll inn alt som trengs. Er ikke du rett person til å opprette en profil for organisasjonen, så gi rett vedkommende beskjed eller ta kontakt med oss slik at vi kan hjelpe til med å få det i orden.                <br/> <br/>
                <h3>Hvordan blir jeg et medlem av en gruppe?</h3>
                Du oppretter en bruker. På steg 3 velger du ønsket organisasjon. Hvis den ikke er registrert, kan du enkelt registrere den ved å trykke på «finner ikke din org?» i bunnen.
                <br/> <br/>
                <h3>Er informasjonen min trygg hos Grasrota?</h3>
                Ja, det er den! Grasrota er fullstendig i overensstemmelse med GDPR-lovgivningen. Som nevnt i personvernserklæringen deler vi ikke informasjonen din med andre om det ikke er nødvendig for å utføre tjenesten eller om vi skulle bli pålagt det av lov, regulering eller rettsprosess.
                <br/> <br/>
                <h3>Kan jeg skjule kontaktinformasjonen min fra andre medlemmer?</h3>
                Det eneste av personlig informasjon som er synlig for andre medlemmer er brukernavn/navn og profilbilde, som vi trenger for grunnleggende funksjoner i appen. For brukere som er registrert med facebook blir ditt fulle navn brukernavnet. Registrer man seg ved bruk av vår innloggingsprosess, kan man selv velge ett brukernavn. Du kan ikke endre brukernavnet ditt etter registrering. Profilbildet kan du endre når som helst.
                <br/> <br/>
                <h3>Hvorfor kan jeg ikke finne en gruppe?</h3>
                Hvis den ikke er registrert, kan du enkelt registrere den ved å trykke på «finner ikke din org?» i bunnen. Hvis du er kontaktperson for din organisasjon, velger du «Fyll inn org. info» og fyller inn all nødvendig info. Er du medlem i din organisasjon, velger du «Kontakt din org.» og fyller inn all nødvendig info. Vi vil da komme i kontakt med organisasjonen din.
                <br /><br />
                <h3>Hvorfor er det delt inn i eksempelvis organisasjoner, klubber etc.?</h3>
                De kjøper ”type likt FIFA spillet” tilgangen til å lage seg en profil og delta i konkurranse med andre deltakere fra sine idrettslag, klubber, foreninger, korps, russ, frivillige organisasjoner, osv. osv. 
Det er fordi man konkurrerer med quizer mot det lokale eller innad i en klubb eller en vennekrets. Folk med samme interesse det skaper gjerne mer sosialt samvær, tilstedeværelse med en lokalforankring. Den er til for den vanlige mannen og kvinnen i gata. 
            </p></div>
            <Footer />
        </>
    )
}

export default Oftespor
