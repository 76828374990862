import React from 'react'
import './Teamwork.css'

function Teamwork() {
    return (
        <div className="teamwork-container">
            <div className="right-container">
                <div className="team-text">
                    <h2>TEAMWORK</h2>
                    <p>«Team» opp med laget ditt, ta grasrota-quizene sammen, og promoter gjerne grasrota appen. En promotør vil generere flere "quizers" ved å dele appen i sosiale medier. Promoteringen gjennomføres digitalt og miljøvennlig ved å dele Grasrota appen, sammen med dine lagspillere, venner og bekjente.</p>
                </div>
            </div>
            <div className="left-container">
                <img src="/assets/greyBack.png" alt="grey-background"/>
            </div>
        </div>
    )
}

export default Teamwork
