import React from 'react'
import { Link } from 'react-router-dom'
import './ExtendedBack.css'

function ExtendedBack() {
    return (
        <div className="extended-container">
            <img src="/assets/extendedBack.png" alt="background"/>
            <div className="content-txt" align="center">
                <h2>Ønsker du å registrere din organisasjon?</h2>
                <br/>
                <br/>
                <Link to='/newOrg' className="content-link">
                    Fortsett <i class="fas fa-chevron-right"></i>
                </Link>
            </div>
        </div>
    )
}

export default ExtendedBack
