import React from 'react'
import './EasyCard.css'

function EasyCard({number, title, txt}) {
    return (
        <div className="card" align="center">
            <div className="card-nubmer">
                <h3>{number}</h3>
            </div>
            <h3>{title}</h3>
            <br/>
            <p>{txt}</p>
        </div>
    )
}

export default EasyCard
