import React from 'react'

function policy() {
    return (
        <div>
<h3>PERSONVERNERKLÆRING</h3>
KORT FORTALT: Her er et kort sammendrag av våre løfter til deg når det gjelder hvordan vi behandler dine personopplysninger. Du bør lese hele personvernerklæringen for fullstendighets skyld. <br/>
• Vi behandler dine personopplysninger i overensstemmelse med personopplysningsloven og personvernerklæringen vår. Vi ønsker fullstendig åpenhet om hva vi gjør med personopplysningene dine. Om du har spørsmål er det bare å ta kontakt. <br/>
• De fleste opplysningene vi har om deg er opplysninger du selv gir oss når du registrerer en profil eller som skapes når du bruker Grasrota. Vi vil også motta visse transaksjonsopplysninger hvis du bruker Grasrota. <br/>
• Personopplysningene dine deles ikke med noen med mindre vi må dele for å levere tjenesten, eller du har gitt oss et særlig samtykke. Vi må dele kontonummeret ditt med våre leverandører av betalingstjenester for å kunne spore og fordele transaksjoner knyttet til Grasrota. <br/>
• Du kan når som helst oppdatere personopplysningene registrert i din profil, og vi oppbevarer ikke opplysningene lenger enn det som er nødvendig ut fra formålet og lovpålagte plikter. <br/>
• Barn under 16 år kan benytte tjenesten, men så fremt barnets foresatte er lagt inn som bruker, vil disse kunne se barnets aktivitet (for eksempel arrangementer de deltar på eller kommentarer de gir). Aldersgrensen for å opprette grupper i tjenesten, eller på annen måte være gruppeadministrator, er 16 år. <br/>
• Andre Grasrota -brukere kan få tilgang til informasjonen din. <br/>
<br/>
<strong>HELE PERSONVERNERKLÆRINGEN:</strong> Vi må behandle en del opplysninger om deg for at vi skal kunne levere, og du skal kunne bruke, Grasrota («tjenesten»). Det er viktig for Grasrota at ditt personvern ivaretas når du benytter tjenesten, og at du forstår hvordan vi bruker personopplysningene dine. I denne personvernerklæringen forklarer vi blant annet hvilke opplysninger vi samler inn, hvorfor, og hva vi bruker opplysningene til. Denne personvernerklæringen er en del av Grasrota brukervilkår. Ved å godta personvernerklæringen og brukervilkårene aksepterer du at Grasrota behandler personopplysningene dine i samsvar med personvernerklæringen. <br/>
<br/>
<br/>
<h3>1 Informasjon vi samler inn</h3>
Opplysninger du gir oss <br/>
I tilknytning til profilen din kan du registrere blant annet navn(navn for facebook brukere, brukernavn for «våre» brukere), e-post, telefonnummer, adresse, fødselsdato( vi samler ikke inn adresse og fødselsdato) og betalingskortnummer (vi samler ikke inn kortnummer for vanlige brukere). Du kan dessuten velge å legge inn et profilbilde. <br/>
Innlegg, kommentarer og meldinger <br/>
Når du bruker Grasrota kan du skrive innlegg, kommentarer og meldinger som er tilgjengelig for mottaker og/eller de andre gruppemedlemmene. Du bør ikke dele sensitive personopplysninger eller andre opplysninger som du ikke ønsker at andre skal kjenne til. <br/>
Opplysninger Grasrota registrerer når du bruker tjenesten <br/>
Vi behandler informasjon i forbindelse med din bruk av tjenesten, blant annet (i) hvilke grupper du deltar i og din aktivitet i gruppene, (ii) domenet som tjenesten blir besøkt fra, (iii) hvilken nettleser som benyttes, (iv) varigheten av besøket, (v) IP-adresse, (vi) enhetsinformasjon, (vii) informasjonskapsler, (viii) anonyme identifikatorer, (ix) tidspunkt for besøket, (x) hvordan du benytter tjenesten (deriblant hvilke funksjoner du benytter), (xi) hvor mange grupper du oppretter, (xii) informasjon om geografisk posisjon og (xiii) hvor lang tid det tar for deg å gjennomføre oppgaver i tjenesten. <br/>
Opplysninger vi samler inn fra andre <br/>
Dersom du bruker Vipps, mottar vi informasjon om kjøpstidspunkt og beløpet som ble betalt. Vi mottar ingen ytterligere kjøpsdetaljer. <br/>
<br/>
<br/>
<h3>2 Hvordan vi bruker personopplysningene dine</h3>
Gjennomføring av avtalen med deg <br/>
Grasrota bruker personopplysningene dine til å levere tjenesten som avtalt. Vi bruker også opplysningene til å gi deg en best mulig brukeropplevelse, for eksempel i form av automatisk pålogging og tilpasning av tjenesten til din enhet. <br/>
Personalisering av tjenestenInformasjonen blir brukt til å personalisere tjenesten, for eksempel ved å finne innhold som er relevant for deg eller andre brukere eller grupper av brukere. <br/>
Utvikling, forbedring, statistikk og målingInformasjonen blir brukt til å bedre forstå hvordan brukerne våre får tilgang til og bruker tjenesten, med det formål å bedre kunne respondere på brukernes preferanser og ønsker og å forbedre tjenesten i sin helhet. Informasjonen blir også brukt til andre forsknings- og analyseformål. <br/>
Markedsføring og analyseOverfor brukere som har et aktivt kundeforhold til Grasrota (det vil si at du er registrert bruker eller administrator for en eller flere grupper), vil vi kunne sende markedsføring per e-post, SMS eller andre elektroniske kommunikasjonsmetoder. Slik markedsføring skjer alltid innenfor rammene av markedsføringsloven. Du kan be om ikke å motta slik markedsføring. Hvis du ikke har et aktivt kundeforhold til Grasrota, eller om vi skulle ønske å sende deg markedsføring som faller utenfor rammene av kundeforholdet, vil vi alltid be om ditt samtykke før vi sender deg markedsføring. <br/>
Når du bruker Vipps har vi tilgang til transaksjonsopplysninger som nevnt ovenfor. Ved hjelp av analyseverktøy analyserer vi disse opplysningene slik at vi bedre kan tilpasse vår kommunikasjon, herunder markedsføring, til deg. Du kan når som helst velge bort slik profilering ved å endre innstillingene i tjenesten. <br/>
<br/>
<br/>
<h3>3 Hvordan vi deler personopplysningene dine</h3>
GenereltVi deler bare personopplysningene dine med andre hvis det er nødvendig for å gjennomføre tjenesten eller vi skulle være forpliktet til slik deling i henhold til lov, forskrift eller rettslige prosesser. <br/>
Enhver deling utover slik deling som nevnt i dette punktet krever ditt samtykke. Dette er ikke til hinder for at vi kan benytte databehandlere til å behandle personopplysninger på våre vegne (i medhold av en databehandleravtalen). Noen av våre databehandlere befinner seg utenfor EU/EØS. For å sikre at disse databehandlerne behandler personopplysninger på en forsvarlig måte benytter vi EUs standardavtaler for overføring av personopplysninger til tredjeland.
<br/>
<br/>
<h3>4 Andre Grasrota -brukere kan få tilgang til informasjonen din</h3>
Dine opplysninger (navn, e-postadresse, telefonnummer, bilde og eventuelle opplysninger du gir i innlegg eller kommentarer) er synlige for andre medlemmer av den enkelte Grasrota -gruppen som du er medlem av eller andre deltakere i arrangementer du er en del av. <br/>
For at Grasrota skal være enkelt å bruke til å opprette grupper og arrangementer kan andre brukere av Grasrota søke deg opp hvis de kjenner telefonnummeret ditt eller e-postadressen din. Da vil de også få tilgang til navn, telefonnummer/e-postadresse og bilde hvis du har valgt å registrere det. <br/>
Hvis du ikke ønsker at andre Grasrota -brukere skal få tilgang til informasjonen din slik som beskrevet ovenfor bør du enten begrense informasjonen du oppgir eller ikke bruke tjenesten. <br/>
Dine betalingskort og annen informasjon som det ikke er nødvendig at andre brukere har tilgang til vil ikke bli delt med andre brukere. <br/>
<br/>
<br/>
<h3>5 Innsyn, retting og sletting av personopplysninger</h3>
Du har rett til innsyn i personopplysningene vi har registrert om deg. Hvilke personopplysninger du har registrert eller på annen måte oppgitt fremgår av profilen din og enkelte Grasrota -gruppen du deltar i. Hvis noen av opplysningene vi har registrert om deg ikke er korrekte, oppfordrer vi deg til å gjøre endringer i profilen din. Der kan du også eventuelt slette personopplysningene dine med mindre de er nødvendige for at vi skal kunne levere tjenesten. Du kan også når som helst trekke tilbake eventuelle samtykker du har gitt. Ta gjerne kontakt med oss hvis du trenger hjelp. <br/>
Vi vil uansett ikke lagre personopplysningene dine lenger enn det som er nødvendig for å oppfylle formålet med behandlingen og våre lovpålagte plikter. Merk at din aktivitet i Grasrota (for eksempel påmeldinger, avmeldinger, innlegg, meldinger eller annen kommunikasjon) vil forbli synlig for dem det er relevant for (for eksempel gruppens medlemmer, arrangementets deltakere osv.) selv om du skulle melde deg ut av dine grupper eller slette din Grasrota -profil. Ved å slette din Grasrota -profil vil navnet og kontaktinformasjonen som er knyttet til disse aktivitetene anonymiseres/fjernes. <br/>
<br/>
<br/>
<h3>6 Barns personvern</h3>
Barn under 16 år kan benytte tjenesten. Hvis barnets foresatte er lagt inn som bruker, vil disse kunne se barnets aktivitet (for eksempel arrangementer de deltar på eller kommentarer de gir). Aldersgrensen for å opprette grupper i tjenesten er 16 år. <br/>
<br/>
<br/>
<h3>7 Informasjonskapsler (cookies)</h3>
Vi benytter informasjonskapsler (cookies) og andre teknologier for å øke brukeropplevelsen og få mest mulig effektiv bruk av tjenesten. En informasjonskapsel er et stykke data som sendes fra en internettside som besøkes, og som lagres lokalt på brukerens nettleser. Hensikten er å bruke informasjonskapslene til å bevare data relatert til brukerens preferanser og kontoinnstillinger, samt til å evaluere og sammenstille statistikk om brukerens aktivitet. De fleste nettlesere godtar informasjonskapsler som standard. Du kan når som helst avslå bruken av informasjonskapsler ved å gjøre endre innstillingene i nettleseren til ikke å godta informasjonskapsler. Merk imidlertid at noen deler av tjenesten kanskje ikke fungerer på riktig måte dersom informasjonskapsler blir fjernet. <br/>
<br/>
<br/>
<h3>8 Informasjonssikkerhet</h3>
Vi har gjennomført organisatoriske og tekniske rutiner og tiltak som skal sørge for at dine personopplysninger ikke kommer på avveie, ikke endres utilsiktet, og at de er tilgjengelige ved behov. <br/>
<br/>
<br/>
<h3>9 Endringer i personvernerklæringen</h3>
Vi vil nå og da komme til å oppdatere denne personvernerklæringen for å reflektere eventuelle endringer i tjenesten. Endret personvernerklæring vil i slike tilfeller bli publisert på grasrota.com. Ved større endringer vil vi varsle deg direkte på egnet måte, enten via tjenesten eller e-post. <br/>
<br/>
<br/>
<h3>10 Behandlingsansvarlig og kontaktinformasjon</h3>
Kompetanseløftet AS, Org nr. 918 061 711, Grenåveien 43, 1447 Drøbak. <br/>
Vi kan kontaktes på e-post: <a href = "mailto: post@grasrota.com">post@grasrota.coml</a>, gjelder alle typer henvendelser. <br/>

</div>
    )
}

export default policy
