import React from 'react'
import EasyCard from './EasyCard'
import './Easy.css'

function Easy() {
    return (
        <div className="easy-container" align="center">
            <h3>ENKELT</h3>
            <p>Så enkelt, legger Grasrota appen til rette for en quiz som bidrar til felleskap og mestringsfølelse for alle. Alt i fra enkeltpersoner, til Norges forskjellige idrettslag, foreninger, vennegjenger og frivillige organisasjoner kan nå enkelt og greit ha det gøy med quiz og lek.</p>
            <br/>
            <br/>
            <br/>
            <strong>Kom i gang med 3 enkle steg:</strong>
            <div className="cards-parent">
            <EasyCard {...{title:"LAST NED APPEN", number:"1", txt:"Last ned «Grasrota» appen fra google play eller app store."}}/>
            <EasyCard {...{title:"VELG ORGANISASJON", number:"2", txt:"Registrer deg og velg din organisasjon/gruppe"}}/>
            <EasyCard {...{title:"REGISTRER VIPPS", number:"3", txt:"Når du er registrert forsetter du med vipps, og start quiz"}}/>
            </div>
            <br />
            <br />
            <br />
            <p>Alle abonnenter av Grasrota appen kan delta umiddelbart. Her blir det fart og gøy. Det vil bli publisert nye quizer ukentlig, og abonnentene vil få muligheten til å «quize» seg oppover på tabellen fra uke til uke.</p>
        </div>
    )
}

export default Easy