import React from 'react'
import './Footer.css'
import {  Link } from 'react-router-dom'

function Footer() {
    return (
        <div className="footer-div">


            <table id="table-right">
            <tr>
                <th className="pages">Sider</th>
                <th className="Nopages"></th>
                <th>Juridisk</th>
            </tr>
            <tr>
                <td><Link to="/about" className="footer-link pages">Om oss</Link></td>
                {/*<td><Link to="/quest" className="footer-link pages">Ofte stilte spørsmål</Link></td>*/}
                <td><Link to="/policy" className="footer-link">Personvernbetingelser</Link></td>
            </tr>
            <tr>
                {/*<td><Link to="/about" className="footer-link pages">Om oss</Link></td>*/}
                <td></td>
                <td><Link to="/terms" className="footer-link">Vilkår for tjenesten</Link></td>
            </tr>
            <tr>
                <td></td>
                <td><Link to="/salgsbetingelse" className="footer-link">Salgsbetingelse</Link></td>
            </tr>
            </table>
            <br/>
            <table id="table-left">
            <tr>
                <th><img src="/assets/logo.png" width="150" alt="logo"/></th>
            </tr>
            <tr>
                <td>Kompetanseløftet AS</td>
            </tr>
            <tr>
                <td>Org.nr: 918 061 711</td>
            </tr>
            </table>
        </div>
    )
}

export default Footer