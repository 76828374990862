import React from 'react'

function LisensData() {
    return (
        <div>
            <center><h3>ROYALTY AVTALE</h3></center><br />
            <center><h3>MELLOM</h3></center><br />
            <center><h3>DERE (DERE) OG KOMPETANSELØFTET AS (KL) </h3></center><br />
            Dagens dato er det inngått følgende royalty avtale mellom partene. Denne signeres til slutt i opplysningsdelen under ”Royalty avtale klar for signering” ved at du sender den inn.
            <br /><br />
            <h3>1. Bakgrunn</h3>
            KL eier, utvikler og driver en digital lære og mestringsplattform som heter Grasrota, www.grasrota.com og Grasrota Appen. <br />
            Grasrota er en Quiz basert kunnskapsplattform (for pc, nettbrett, mobiltelefon) hvor deltagerne kan Quize mot seg selv ved å delta sammen med mange andre. Tjenesten skal med denne royalty avtalen tilbys deres medlemmer og markedet for øvrig, alle. <br /><br />
            Deres medlemmer kan abonnere på tjenesten ved å tegne seg for et abonnement, og ved å betale for et digitalt produkt som knyttes til navnet på klubben i lære og ferdighets plattformen.
            <br /><br />
            <h3>Abonnement typer</h3>
            Prisene på de enkelte digitale abonnementene/produktene vi tilbyr til våre Quiz og ferdighets brukere pr. mnd. dette når alt er med logo/emblem/navnet til bruk i og på profilbildet eller tjenesten for øvrig: <br />
            A - Platinum kr 250,- <br />
            B - Gull kr 200,- <br />
            C - Sølv kr 150,- <br />
            D - Bronse kr 100,- <br />

            DERE får Royalty inntekter ved å lisensiere ut logo/emblem/navnet til KL ut i fra denne avtalen, og det etter gitte satser. DERE må selv legge til egen logo/emblem i Grasrota Appen som skal benyttes og det digitalt.
            <br /><br />
            <h3>2. Partene sine rettigheter og plikter</h3>
            KL betaler Royalty til opphavet av “Emblem, navnet – Logoer”, ved å få denne tillatelsen til å bruke logo/emblem fra klubber, lag eller foreninger i vår digitale lære og mestringsplattform Grasrota Appen. Vi estimerer Royalty med et estimert volum som vi skal levere ut i fra. Formelen på estimatet/volumet gjelder ut i fra prinsippet så lenge etterspørselen og deltakelsen er god, målt i antall løpende abonnenter. I vår Grasrota App ligger stort sett alle de klubber/foreninger/organisasjoner mfl. som våre brukere kan velge mellom når de skal sette opp sin profil for så å konkurrere i digital lære og mestring. Dette være seg quiz eller annet gøy som gir samhold i vår digitale kunnskap/lære-og konkurranse plattform, Grasrota Appen.<br /><br />
            All betaling til KL skjer trygt i Grasrota Appen med Vipps sin egen betalingsplattform integrerte. Quizene er abonnement basert. Brukerne betaler for sin deltakelse en gang pr mnd. Vi opererer med 1 mnd. oppsigelse for alle brukerne. <br /><br />
            Vi har også en web-side <a href="https://grasrota.com">www.grasrota.com</a> som forklarer og kommuniserer med brukerne samt en god presentasjon av Grasrota Appen, som nå i første omgang kun inneholder varianter av Quiz lære og ferdighets konkurranser. En spørsmål Quiz med svaralternativer, rangeringsliste og pallplassering. Det vil også bli videoquiz en gang i uken på sikt, hvor kjent eller kjære personer vil stå for videoquizen. Etterhvert vil det også komme flere attraktive lære og mestrings tjenester i Grasrota Appen. Det her er også nøkkelferdige løsninger for mulige langvarige royalty lisensierte inntekter og det nesten uten terskel for våre brukere, på vår lære og ferdighets plattform. <br /><br />
            <br />
            
            a. KL skal drifte lære og mestringsplattformen, Quiz og produsere alle digitale tjenester. KL selger og tar betalt fra sine brukere for sine produkter. Hver 2. måned foretas en Royalty avregning og KL betaler DERE den løpende satsen som kommer tydelig fram i en vedlagt oversikt. <br /><br />
            b. DERE gir KL tillatelse med denne avtalen til å lage nevnte produkter med DERE sin logo/emblem/navn samt lage en ramme rundt quizen som viser klubbens identitet være foto, illustrasjon eller stemnings bilder. KL har ingen ytterligere rett til å bruke DERE sin logo/emblem/navn ut over denne avtalen. Det skal vises ytterst varsomhet ved bruken. <br /><br />
            DERE skal markedsføre KL sin Grasrota App (plattform) for medlemmer, dele i sosiale medier samt opplyse der det måtte gange tjenesten. DERE står veldig fritt til å velge hvilken måte å spre budskapet blant deres medlemmer på. Det forutsettes at man bruker etablerte kanaler som nyhetsbrev, eposter, møter, YouTube videoer, Facebook, Snapchat, Instagram  o.l. til markedsføringen. KL vil bidra med standard markedsføring materiell som enkelt kan tas i bruk digitalt om ønskelig. <br /><br />
            <br /> <br />

            <h3>3. Partene sine økonomiske forhold</h3>
            KL tilbyr sine lære og mestrings produkter og tar betalt direkte fra brukeren etter nevnte abonnement priser i avtalens pkt. 1. <br /><br />
            Produktet definerer hvilke type abonnement KL sine kunder velger. <br /><br />

            Hver 2. måned skal KL frem legge royalty avregning til DERE som viser hvor mange og hvilke lære og mestringsprodukter som er levert og i bruk med DERE sitt logo/emblem/abonnement. <br />
            Samtidig fakturerer DERE KL for den avtalt royalty. <br /><br />


            DERE mottar ca.70% av kjøpesummene der det er gjort bruk av logo/emblem/navnet på KL sine produkter solgt til KL sine brukere i lære og ferdighets plattformen. <br /><br />

            Prisene kan reguleres av KL med 1 måneds varsel.
            <br /><br />
            <h3>4. Avtalen sin varighet</h3>
            Denne avtale er løpende, men kan enkelt sies opp gjensidig av begge parter med 2 måneders varsel om oppsigelse av avtalen.

            <br /><br />

            <h3>5. Brudd på denne avtalen</h3>
            Brudd på denne avtale er å anse som mislighold av kontraktsforpliktelsen og vil medføre at avtalen opphøre jfr. Pkt. 4.
            <br /><br />
            <h3>6. Verneting</h3>
            Rett verneting ved tvister om denne avtale er Follo tingrett.  <br />
            Eventuelt tvister eller uenigheter skal selvfølgelig først forsøkes løst ved god tone og i minnelighet om det skulle bli nødvendig.

            <br /><br />
            <h3>Ved å sende denne inn så regnes det som signert avtale 😀</h3>
            <br /><br /><br />
                        <br />
        </div>
    )
}

export default LisensData