import React from "react"
import { ThreeDots } from "react-loader-spinner";

function LoaderBar({btnIsColored}) {
  return (
    <ThreeDots color={btnIsColored ? "white":"#2EACA2"} height={10} />
  )
}

export default LoaderBar
