import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'

function Navbar() {

    const [click, setClick] = useState(false)
    const [button, setButton] = useState(true);
    const [back, setBack] = useState(false);

    let lastKnownScrollPosition = 0;

    const handleClick = () => setClick(!click);

    const showButton = () => {
        if (window.innerWidth <= 600) {
            setButton(false)
        } else {
            setButton(true)
        }
    };

    const showBack = () => {
        lastKnownScrollPosition = window.scrollY;
            if (lastKnownScrollPosition < 200 ) {
                if (lastKnownScrollPosition > 15) {
                    setBack(true)
                } else {
                    setBack(false)
                }
            }
    };

    useEffect(() => {
        showButton();
        showBack();
    }, []);

    window.addEventListener('resize', showButton)
        window.addEventListener('scroll', function(e) {
            showBack()
        });

    return (
        <>
        <nav className={window.location.pathname === "/" ? back || !button ? "navbar" : "navbar firstPageNav" : "navbar"}>
            <div className="navbar-container">
                <Link to='/' className='navbar-logo'>
                    <img src="/assets/logo.png" alt="logo"/>
                </Link>
                <ul className={button ? 'nav-menu' : click ? 'nav-menu mobile open' : 'nav-menu mobile close'}>
                    <li className="nav-item">
                        <Link to='/' className="nav-links">
                            Hjem
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/about' className="nav-links">
                            Om oss
                        </Link>
                    </li>
                    {/*<li className="nav-item">
                        <Link to='/quest' className="nav-links">
                            Ofte stilte spørsmål
                        </Link>
                    </li>*/}
                </ul>
                {button ? "" : click ? <i className="fas fa-times" onClick={handleClick}></i> : <i className="fas fa-bars" onClick={handleClick}></i>}
            </div>
        </nav>
    </>
    )
}

export default Navbar
