import React,{ useEffect } from 'react'

function LisSuc() {
  useEffect(() => {
    setTimeout(() => {
    window.location.href = "https://grasrota2.page.link/app"
    }, 3000);
  }, [])
  
  return (
    <div className='center' style={{textAlign:"center"}}>
        <i className="fas fa-check-circle" style={{fontSize: "40px",color: "green"}}></i>
        <br />
        <h2>Avtalen er registrert og du vil motta en mail</h2>
    </div>
  )
}

export default LisSuc