import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../components/About.css'

function About() {
    return (
        <>
            <Navbar />
            <div align="center">
            <div className="about-txt">
            <h2>Kompetanseløftet AS</h2>
            <br/>
            <p>
            Grasrota Appen er et satsningsområde gjort av Kompetanseløftet AS som for øvrig også har en lang fartstid i å hjelpe de litt eldre i samfunnet med å beherske nettbrett, pc og smarttelefoner ...
            </p>
            <br/>
            <strong>Email: post@grasrota.com</strong>
            </div>
            </div>
            <Footer />
        </>
    )
}

export default About
