import React, { useEffect } from 'react'
import Banner from '../components/Banner'
import Navbar from '../components/Navbar'
import '../components/BannerContent.css'
import Footer from '../components/Footer'
import TermsText from '../components/termsText'

function Terms() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <Navbar />
            <Banner {...{title: "VILKÅR FOR BRUK", underTxt: ""}} />
            <div align="center"><p className="banner-content" align="center">
            <TermsText />
            </p></div>
            <Footer />
        </>
    )
}

export default Terms
