import {
    initializeApp
} from "firebase/app"
import * as fireauth from "firebase/auth"
import {
    getFirestore
} from 'firebase/firestore'
import {
    getFunctions
} from "firebase/functions"
import "firebase/storage"
import {
    getStorage
} from "firebase/storage"

const fire = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
})

export const auth = fireauth;
export const fireFunctions = getFunctions(fire);
export const storageRef = getStorage(fire);
export const fireStore = getFirestore(fire);



export default fire