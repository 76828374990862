import React from 'react'
import Navbar from '../components/dashboard/navbar/Navbar';
import "../components/dashboard/Dashboard.css"
import Quizes from '../components/dashboard/quizes/Quizes';
import { Route } from 'react-router-dom';
import Orgs from '../components/dashboard/orgs/Orgs';
import { auth } from '../firebase';

function Dashboard({user}) {

    return (
        <>
        <Navbar email={user["email"]} />
        <div onClick={() => auth.signOut(auth.getAuth())} style={{position:"absolute",top:"15px",right:"15px",fontWeight:"bold",cursor:"pointer"}}><i className="fas fa-sign-out-alt"></i> LOGGUT</div>
            <div className='dashboard-content'>
                <Route path='/panel' exact render={() => <Quizes />} />
                <Route path='/panel/orgs' exact render={() => <Orgs />} />
            </div>
        </>
    )
}

export default Dashboard
