import React from 'react'
import './Banner.css'

function Banner({title, underTxt}) {
    return (
        <div className="banner-container">
            <h2>{title}</h2>
            <p>{underTxt}</p>
        </div>
    )
}

export default Banner
