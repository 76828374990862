import { addDoc, collection, doc, setDoc, writeBatch, getDocs } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { fireStore } from '../../../firebase'
import SubmitBtn from '../../../shared/constant/SubmitBtn'
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import makeid from '../../../shared/Functions/functions';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function NewQuiz({ changeview, editId = null }) {
    const [numberOfquests, setnumberOfquests] = useState(1)
    const [isLoading, setisLoading] = useState(false)
    const [editData, seteditData] = useState({})

    async function addQuiz() {        
        if (document.getElementById("question-1").value != "" && document.getElementById("alt1-1").value != "" &&
            document.getElementById("alt1-2").value != "" && document.getElementById("alt1-3").value != "" &&
            document.getElementById("alt1-4").value != "" && document.getElementById("active-date").value != "") {

            setisLoading(true)
            var myDate = new Date(document.getElementById('active-date').value);
            var dateStr = myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();

            const dataCol = editId ? doc(fireStore, "quiz", editId["id"]) : doc(collection(fireStore, 'quiz/'));
            let resolveAfter3Sec = setDoc(dataCol, {
                "date": dateStr,
                "numberOfQuests": numberOfquests,
                "week": new Date(myDate).getWeekNumber(),
            }).then(() => {
                addQuestions(dataCol.id)
                //quizData(docRef, isEdit, doneProg, currNum, editStr);
            }).catch((err) => {
                console.log(err);
            })
            toast.promise(
                resolveAfter3Sec,
                {
                  pending: 'Lagrer quiz',
                  success: 'Quiz lagret',
                  error: 'Noe gikk galt'
                }
            )
            setisLoading(false)
        } else {
            alert("Mangler input")
        }
    }

    async function addQuestions(docId) {

        const batch = writeBatch(fireStore);
        const storage = getStorage();
        const storageRef = ref(storage, 'quiz/' + docId + "/");

        for (let i = 1; i <= numberOfquests; i++) {
            const ind = i;
            var quest = document.getElementById("question-" + ind).value;
            var alt1 = document.getElementById("alt" + ind + "-1").value;
            var alt2 = document.getElementById("alt" + ind + "-2").value;
            var alt3 = document.getElementById("alt" + ind + "-3").value;
            var alt4 = document.getElementById("alt" + ind + "-4").value;
            var img = document.getElementById("image-input-" + ind);
            var imgShow = document.getElementById("image-show-" + ind);
            var vid = document.getElementById("video-input-" + ind);
            var vidShow = document.getElementById("video-show-" + ind);

            const sourcesImg = i.toString() in editData && ("src" in editData[ind.toString()]) && (editData[ind.toString()]["src"]["isImage"]) ? editData[ind.toString()]["src"]["src"] : null
            const sourcesVid = i.toString() in editData && ("src" in editData[ind.toString()]) && (!editData[ind.toString()]["src"]["isImage"]) ? editData[ind.toString()]["src"]["src"] : null

            var uploadData = {
                "ans1": alt1,
                "ans2": alt2,
                "ans3": alt3,
                "ans4": alt4,
                "answer": alt1,
                "question": quest,
            }

            if ((sourcesImg !== imgShow.src && sourcesVid !== vidShow.src) && (img.files[0] || vid.files[0])) {
                const imgId = makeid(5)
                const refce = ref(storageRef, img.files[0] ? imgId : imgId)
                
                await uploadBytes(refce, img.files[0]).then(async (snapshot) => {
                    await getDownloadURL(snapshot.ref).then((url) => {
                        uploadData["src"] = { "isImage": img.files[0] ? true : false, "src": url }
                    })
                });

            } else if(imgShow.src === sourcesImg) {
                uploadData["src"] = { "isImage": true, "src": sourcesImg}
            } else if(vidShow.src === sourcesVid) {
                uploadData["src"] = { "isImage": false, "src": sourcesVid}
            }

            const docRef = doc(fireStore, 'quiz/' + docId + "/questions/", ind.toString());
            batch.set(docRef, uploadData)
        }

        if (editId) {
            if (editId["count"] > numberOfquests) {
                for (let i = numberOfquests + 1; i <= editId["count"]; i++) {
                    const docRef = doc(fireStore, 'quiz/' + docId + "/questions/", i.toString());
                    batch.delete(docRef)
                }
            }
        }
        await batch.commit().then(() => {
            //changeview()
        });
    }

    Date.prototype.getWeekNumber = function () {
        var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
        var dayNum = d.getUTCDay() || 7;
        d.setUTCDate(d.getUTCDate() + 4 - dayNum);
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        return Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
    };

    async function fetchAllQuestions() {
        const dataCol = collection(fireStore, 'quiz/' + editId["id"] + "/questions");

        const dataSnapshot = await getDocs(dataCol);
        let data = {}
        const allDocs = dataSnapshot.docs.map((doc) => {
            const docData = doc.data()
            docData["id"] = doc.id
            data[doc.id] = docData
        });

        seteditData(data)
        setnumberOfquests(allDocs.length)
    }

    useEffect(() => {
        if (editId) {
            fetchAllQuestions()
        }
    }, [])

    const getDate = (date = "") => {
        let splt = date.split("-")
        let year = splt[0].toString()
        let month = splt[1].toString()
        if (month.length < 2) month = "0" + month

        let day = splt[2]
        if (day.length < 2) day = "0" + day

        return year + "-" + month + "-" + day
    }

    const showImg = (event=null, url = null, ind, isImg = true) => {
        try {
            if (isImg) {
                console.log("1:"+ind);

                let elm = document.getElementById('image-show-' + ind)
                if (event !== null) {
                    console.log("2:"+ind);

                    elm.src = URL.createObjectURL(event.target.files[0]);
                    elm.onload = function () {
                        URL.revokeObjectURL(elm.src) // free memory
                    }
                } else if (url) {
                    elm.src = url
                    console.log("3:"+ind);
                }

                document.getElementById('upload-img-' + ind).style.background = "black";
                document.getElementById('upload-vid-' + ind).style.display = "none"
                document.getElementById('image-remove-source' + ind).style.display = "block"
            } else {
                console.log("sdfsdfdsf");
                document.getElementById('upload-img-' + ind).style.display = "none"
                document.getElementById('video-remove-source' + ind).style.display = "block"
            }
        } catch (error) {
            console.log(error);
        }
    }

    const removeSource = (ind) => {
        document.getElementById('upload-img-' + ind).style.background = "rgba(86, 190, 86, 0.882)";

        document.getElementById('upload-vid-' + ind).style.display = "inline-block"

        document.getElementById('image-remove-source' + ind).style.display = "none"

        document.getElementById('image-input-' + ind).value = ""

        document.getElementById('image-show-' + ind).removeAttribute('src');

    }

    var rows = [];
    for (var i = 1; i <= numberOfquests; i++) {
        const ind = i;
        rows.push(<div id={'quest-' + i} className="question-parent">
            <div className='questNumInd'>
                <div className='circleNum center'><div>{i}</div></div>
            </div>
            <p>Spørsmål</p>
            <input className='question' type="text" id={'question-' + i} defaultValue={i.toString() in editData ? editData["" + i]["question"] : ""} />
            <p>Svaralternativ</p>
            <input type="text" id={'alt' + i + '-1'} className="alternative" placeholder='alt. 1 (riktig svar)' defaultValue={i.toString() in editData ? editData["" + i]["ans1"] : ""} />
            <input type="text" id={'alt' + i + '-2'} className="alternative" placeholder='alt. 2' defaultValue={i.toString() in editData ? editData["" + i]["ans2"] : ""} />
            <br />
            <input type="text" id={'alt' + i + '-3'} className="alternative" placeholder='alt. 3' defaultValue={i.toString() in editData ? editData["" + i]["ans3"] : ""} />
            <input type="text" id={'alt' + i + '-4'} className="alternative" placeholder='alt. 4' defaultValue={i.toString() in editData ? editData["" + i]["ans4"] : ""} />
            <br />

            <div id={'upload-img-' + i} className="upload-source" style={{ background: "rgba(86, 190, 86, 0.882)" }}>
                <img id={'image-show-' + i} />
                <input id={'image-input-' + ind} type='file' accept='image/*' onChange={(event) => showImg(event,null, ind)} />
                <div className='center'>
                    <i className="fas fa-image"></i>
                    <h5>Bilde</h5>
                </div>
                <div id={'image-remove-source' + ind} onClick={() => removeSource(ind)} className='closeSource'><i className="fas fa-times"></i></div>
            </div>
            <div id={'upload-vid-' + i} className="upload-source" style={{ background: "orange" }}>
                <img id={'video-show-' + i} />
                <input id={'video-input-' + ind}  type='file' accept='video/*' onChange={(event) => showImg(event, null, ind, false)} />
                <div className='center'>
                    <i className="fas fa-video"></i>
                    <h5>Video</h5>
                </div>
                <div id={'video-remove-source' + ind} onClick={() => removeSource(ind)} className='closeSource'><i className="fas fa-times"></i></div>
            </div>
        </div>);
        if (ind.toString() in editData && "src" in editData[ind.toString()] && ind.toString() in editData) {
            if (editData[ind.toString()]["src"]["isImage"]) {
                showImg(null, editData[ind.toString()]["src"]["src"], ind, true)
            } else {
                showImg(null, editData[ind.toString()]["src"]["src"], ind, false)
            }
        }
        
    }

    return (
        <>
        <ToastContainer />
        <div className="question-parent">
            <h2>Legg til ny quiz</h2>

            <br />
            <button className='back-btn' onClick={() => changeview()}><i className="fas fa-arrow-left"></i> Tilbake</button>
            <div className='table-container'>

                {rows}
                <br />
                <button className='addRev' onClick={() => setnumberOfquests(numberOfquests + 1)}><i className="fas fa-plus-circle"></i> Legg til spørsmål</button>
                {numberOfquests > 1 ? <button className='addRev' onClick={() => setnumberOfquests(numberOfquests - 1)}><i className="fas fa-minus-circle"></i> Fjern siste spørsmål</button> : ""}

                <br />
                <br />
                <input type="date" id={'active-date'} className="alternative" defaultValue={editId ? getDate(editId["date"]) : ""} />
                <br />
                <br />

                <SubmitBtn isLoading={isLoading} text={"Lagre"} onclick={() => addQuiz()} />
            </div>
        </div>
        </>
    )


}

export default NewQuiz