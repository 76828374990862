import React, { useEffect, useState } from 'react'
import SubmitBtn from '../../../shared/constant/SubmitBtn';
import { fireStore } from '../../../firebase'
import { collection, getDocs, getDoc, limit, query, writeBatch, doc,deleteDoc } from '@firebase/firestore'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteObject, getStorage, listAll, ref } from 'firebase/storage';

function QuizList({ changeview }) {
    const [quizes, setquizes] = useState([])

    async function fetchQuiz() {
        const dataCol = collection(fireStore, 'quiz/');
        const q = query(dataCol, limit(50));

        const dataSnapshot = await getDocs(q);
        const dataList = dataSnapshot.docs.map((doc) => {
            const docData = doc.data()
            docData["id"] = doc.id
            return docData
        });

        setquizes(dataList)
    }

    async function deleteQuiz(quizId, number = 0,index) {
        const batch = writeBatch(fireStore);

        for (let i = 1; i <= number; i++) {
            const docRef = doc(fireStore, 'quiz/' + quizId + "/questions/", i.toString());
             batch.delete(docRef)
        }

        listAll(ref(getStorage(), "quiz/"+quizId)).then((items) => {
            items.items.forEach((item) => {
                deleteObject(item)
            })
        })

        let resolveAfter3Sec =  batch.commit().then(async() => {
            await deleteDoc(doc(fireStore, "quiz", quizId));
            
            fetchQuiz()
        });
        toast.promise(
            resolveAfter3Sec,
            {
              pending: 'Fjerner quiz',
              success: 'Quiz fjernet',
              error: 'Noe gikk galt'
            }
        )


    }

    useEffect(() => {
        fetchQuiz()
    }, [])

    return (
        <>
        <ToastContainer />

        <div>
            <h2>Alle quizene i appen</h2>

            <br />
            <SubmitBtn onclick={() => changeview()} text={"Lag ny quiz"} />

            <div className='table-container'>
                <table className='quiz-table'>
                    <tr>
                        <th>Publisert</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                    {quizes.map((item,i) => {
                        return <tr><td>{item["date"]}</td><td style={{ color: "green", fontWeight: "bold" }}>AKTIV</td><td style={{ float: "right", marginTop: "13px" }}><button onClick={() => changeview({"id": item["id"],"date":item["date"],"count": item["numberOfQuests"]})} className='dashbaord-list-edit'>Endre</button><button onClick={() => deleteQuiz(item["id"],item["numberOfQuests"],i)} className='dashbaord-list-delete'>Slett</button></td></tr>
                    })}
                </table>
            </div>
        </div>
        </>
    )
}

export default QuizList