import React, { useEffect } from 'react'
import Easy from '../components/Easy'
import ExtendedBack from '../components/ExtendedBack'
import Footer from '../components/Footer'
import Frontpage from '../components/Frontpage'
import Images from '../components/Images'
import Navbar from '../components/Navbar'
import Teamwork from '../components/Teamwork'

function Home() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <Navbar />
            <Frontpage />
            <Teamwork />
            <Images />
            <Easy />
            <ExtendedBack />
            <Footer />
        </>
    )
}

export default Home
