import React from 'react'
import './Images.css'

function Images() {
    return (
        <div align="center">
        <div className="image-container">
            <img src="/assets/phone.png" alt="image1"/>
            <img src="/assets/telefon.png" alt="image2"/>
        </div>
        </div>
    )
}

export default Images